.wrapper {
  @apply flex;

  &:global(.layout-align-left) {
    @apply max-w-8xl mx-auto;
    & > * {
      @apply max-w-1xl;
    }
  }
}

.wrap {
  @apply flex-wrap;
}

.center {
  @apply justify-center;
}

.left {
  @apply justify-start;
}

.right {
  @apply justify-end;
}

.between {
  @apply justify-between;
}

.col {
  @apply flex-col;
}

.spacing {
  @apply space-x-6;
}
